// @flow

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@latitude/box';
import Helmet from 'react-helmet';
import { Link } from '@latitude/link';
import { Lframe } from '@latitude/lframe';
import { Hero } from '@latitude/hero';
import { FeaturesSlider } from '@latitude/features-slider';
import SvgInline from '@latitude/svg-inline';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Heading3, Heading5 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { BrandedCallout, Callout, CalloutTitle } from '@latitude/callout';
import {
  BREAKPOINT,
  COLOR,
  BUTTON_STYLE,
  MARGIN,
  ALIGN,
  JUSTIFY_CONTENT,
  PADDING,
  FLEX_WRAP
} from '@latitude/core/utils/constants';
import Layout from '../components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '../data/pages/about-us.json';
import imgHero from '../images/lframe/L-shape-about-us.png';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const AboutUsPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: []
  });

  useEffect(() => {
    let [heroBanners] = [[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData: heroBanners.length > 0 ? heroBanners : state.heroBannerData,
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Layout>
      <MobileAppInstallPrompts />

      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/about-us/"
            />
            <title>About Us | Gem by Latitude</title>
            <meta
              name="description"
              content="We are part of Latitude, a leading digital payments and finance business in Australia and New Zealand."
            />
          </Helmet>

          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
          <Hero
            title={PageData.content.hero.title}
            text={PageData.content.hero.text}
            css={` && {
              background: #0146aa;
              background: linear-gradient(135deg, #0146aa 0%, #0162ed 100%);
              color: #fff;

              h1,
              div,
              p {
                color: #fff;
              }

                .HeroContent {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                  [class^='Linkstyled__StyledLink-'] {
                    color: #0061EE!important;
                    background-color: #fff!important;

                    &:hover {
                      box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 13px 0px;
                      transition: all 150ms ease-in-out 0s;
                      border-bottom: 1px solid rgb(0, 106, 255);
                      text-shadow: rgb(0, 106, 255) 0px 0px 1px;
                      background-color: #cee6ff!important;
                      border-color: #cee6ff;
                    }
                  }
                }
              }
              sup {
                font-size: 0.5em;
                top: -1em;
                line-height: 0;
              }
              .footnote {
                display: block;
                font-size: 14px;
                font-weight: 300;
                line-height: 18px;
                margin-top: 24px;
              }

              @media (max-width: ${BREAKPOINT.MD}) {
                h1,
                div,
                p {
                  color: #fff!important;
                }

                .HeroContent {
                  padding: 10px;
                  background-color: transparent;
                }
              }
            `}
            imageContent={
              <div
                id="hero"
                css={`
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-end;
                  }
                `}
              >
                <img
                  src={require('../images/about-us-banner.webp')}
                  css={`
                    height: 280px;
                    width: auto;
                    margin: 0;
                    @media (min-width: ${BREAKPOINT.MD}) {
                      height: 400px;
                      margin: 70px -60px 0 0;
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 555px;
                      margin: 0 -90px 0 0;
                    }
                  `}
                  alt="Low Rate Card"
                />
              </div>
            }
          />)
          }

          <div className="d-block">
            <StickyNavigation
              items={PageData.nav}
              isSticky={isNavSticky}
              onStickyNavStateChange={setIsNavSticky}
              offsetElem="[data-sticky-navigation-offset]"
            />
          </div>

          <FeaturesSlider
            id="our-company"
            heading="Our Company"
            whiteTiles
            data={PageData.content.ourCompany}
            css={`
              h5 {
                text-align: left;
              }
              p {
                text-align: left;
                @media (min-width: ${BREAKPOINT.SM}) {
                  height: 180px;
                }
                @media (min-width: ${BREAKPOINT.XL}) {
                  height: 140px;
                }
              }
            `}
          />

          <Divider />

          <Callout className="bg-light">
            <div className="row justify-content-center">
              <div
                css={`
                  h4.callout__title {
                    font-size: 24px;
                    @media (max-width: ${BREAKPOINT.XL}) {
                      margin-top: 5px;
                    }
                  }
                `}
              >
                <CalloutTitle
                  className="text-dark mx-4"
                  title="View all Latitude products"
                />
              </div>
              <div className="mx-4">
                <Link
                  trackId="view-all-products-find-out-more"
                  trackEventData={{
                    location: 'View all Latitude products'
                  }}
                  button={BUTTON_STYLE.SECONDARY}
                  href="https://www.gemfinance.co.nz"
                >
                  Find out more
                </Link>
              </div>
            </div>
          </Callout>

          <div css="margin-bottom: 50px">
            <Divider />
          </div>

          <Anchor id="join-the-team" />
          <BrandedCallout
            hasConfettiBackground
            moreVerticalPadding
            heading="Join the team"
            line1="We hire the best people aligned with our values, we build strong and lasting partnerships and we put the customer at the heart of everything."
            cta={
              <Link
                trackId="join-the-team"
                trackEventData={{
                  location: 'Join the team'
                }}
                href="https://www.latitudefinancial.com.au/careers/?isgemf"
                variant="secondary"
                css="margin-top: 16px;"
                button={BUTTON_STYLE.SECONDARY}
              >
                Join the team
              </Link>
            }
          />

          <Anchor id="news-room" />
          <InTheMedia />
          <MediaEnquiry />
        </Box>
      </main>
    </Layout>
  );
};

const MediaEnquiry = () => (
  <Flex
    backgroundColor={COLOR.BLUE_BABY}
    justifyContent={JUSTIFY_CONTENT.CENTER}
    padding={PADDING.P32}
    flexWrap={FLEX_WRAP.WRAP}
  >
    <Box
      paddingLeft={PADDING.P16}
      paddingRight={PADDING.P16}
      css={`
        text-align: ${ALIGN.RIGHT};
      `}
    >
      <SvgInline name="icon-mail" />
    </Box>
    <Box paddingLeft={PADDING.P16} paddingRight={PADDING.P16}>
      <Heading5
        color={COLOR.BLACK}
        marginTop={MARGIN.M16}
        marginBottom={MARGIN.M8}
        css={`
          @media (max-width: 521px) {
            text-align: center;
            font-size: 16px;
          }
        `}
      >
        For media and journalist enquiries only.
      </Heading5>
      <div
        css={`
          @media (max-width: 660px) {
            text-align: center;
          }
          @media (max-width: 521px) {
            font-size: 14px;
          }
        `}
      >
        <Link
          trackId="media-enquiries-email"
          trackEventData={{
            location: 'For media and journalist enquiries only.'
          }}
          className="link--blue text-link__exclude"
          href="mailto:media.enquiries@latitudefinancial.com"
        >
          media.enquiries@latitudefinancial.com
        </Link>
      </div>
    </Box>
  </Flex>
);

const InTheMedia = () => (
  <OuterBox>
    <InnerBox isResponsive>
      <Heading>In the Media</Heading>
      <Text className="text-center text-dark">
        View our latest media releases.
      </Text>
    </InnerBox>
    <Link
      trackId="in-the-media-view-all"
      trackEventData={{
        location: 'In the Media'
      }}
      className="mx-auto"
      href="https://www.latitudefinancial.com.au/about-us/?isgemf#js-heading-media"
      width="160px"
      button={BUTTON_STYLE.TERTIARY}
      css={`
        margin-top: 48px;
      `}
    >
      View All
    </Link>
  </OuterBox>
);

const OuterBox = styled(Box)`
  background-color: #f8f8f8;
  padding-top: 36px;
  padding-bottom: 36px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const InnerBox = styled(Box)`
  margin: 0 auto;
`;

const Heading = styled(Heading3)`
  text-align: center;
  color: ${COLOR.BLACK};
  font-size: 24px;
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 32px;
  }
`;

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -30px;
`;

const Divider = () => (
  <hr
    css={`
      width: 80%;
      height: 1px;
      margin: 1px auto;
      background-color: ${COLOR.GREY16};
      color: ${COLOR.GREY16};
      border: 0 none;
    `}
  />
);

export default AboutUsPage;
